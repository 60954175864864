import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Navigation from './Navigation';
import Logo from './Logo';
import menuIcon from '../assets/img/icon_menu.svg';

// STYLES
const HeaderContainer = styled.header`
  margin-bottom: 40px;
  display: flex;
  align-items: flex-end;
  padding-top: 50px;
  align-items: center;
  
  @media screen and (min-width: 960px) {
    align-items: flex-end;
    padding-top: 0;
    height: 110px;
  }
`;

const StyledLogo = styled(Logo)`
  margin-left: auto;
`;

const NavButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  display: block;
  cursor: pointer;
  
  @media screen and (min-width: 960px) {
    display: none;
  }

  img {
    width: 38px;
    height: 28px;
  }
`;

const HeaderNavigation = styled(Navigation)`
  display: block;
`;

// COMPONENT
class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showMobileNav: false
    }

    this.mobileToggleHandler.bind(this);
    this.mobileCloseHandler.bind(this);
  }

  mobileToggleHandler = () => {
    this.setState(state => ({ showMobileNav: true }));
  };

  mobileCloseHandler = () => {
    this.setState({ showMobileNav: false });
  };

  render() {
    return (
      <HeaderContainer className={this.state.showMobileNav ? 'mobileOpen' : null}>
        <NavButton onClick={this.mobileToggleHandler}><img src={menuIcon} alt=""/></NavButton>
        <HeaderNavigation closeHandler={this.mobileCloseHandler} />
        <StyledLogo />
      </HeaderContainer>
    );
  }
}

export default withRouter(Header);