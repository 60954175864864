import React from 'react';
import Page from '../components/Page';
import { Col, Row } from 'react-styled-flexboxgrid';
import IFrame from 'react-iframe';
import NavigationService from '../components/NavigationService';
import styled from 'styled-components';

// STYLES
const StyledIFrame = styled(IFrame)`
  border: none;
  width: 100%;
`;

const Container = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: 960px) {
    margin-bottom: 0;
  }
`;

// COMPONENT
const Service = props => {

  const { data } = props;

  return (
    <Page title={ data.headline }>
      <Row>
        <Col xs={12} md={3}>
          <Container>
            <h1>Service</h1>
            <NavigationService />
          </Container>
        </Col>
        <Col xs={12} md={9}>
          { data.headline === 'Zahntechnik' ? (
            <React.Fragment>
              <Row>
                <Col xs={5}>
                  <h2>{ data.headline }</h2>
                  <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
                </Col>
                <Col xs={7}>
                  <StyledIFrame 
                    url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5324.778371470136!2d11.535856534146118!3d48.141301698285424!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x75f2df1038a67766!2sZahntechnik%20Steffen%20Hauswurz!5e0!3m2!1sde!2sde!4v1584537716022!5m2!1sde!2sde"
                    width="540" height="380"
                  />
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2>{ data.headline }</h2>
              <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
            </React.Fragment>
          ) }
        </Col>
      </Row>
    </Page>
  );
};

export default Service;