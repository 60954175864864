import React from 'react';
import { withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import cockpit from './utils/cockpit';
import Routes from './Routes';
import Layout from './components/Layout';
import Header from './components/Header';
import 'react-image-lightbox/style.css';

// STYLES
const Main = styled.main`

`;

// COMPONENT
class App extends React.Component {

  state = {};

  componentDidMount() {
    cockpit.getSingleton('start').then(res => {
      this.setState({ start: res.data });
    });

    cockpit.getSingleton('praxis').then(res => {
      this.setState({ praxis: res.data });
    });

    cockpit.getSingleton('aerzte').then(res => {
      this.setState({ aerzte: res.data });
    });

    cockpit.getSingleton('notdienst').then(res => {
      this.setState({ notdienst: res.data });
    });

    cockpit.getSingleton('zahntechnik').then(res => {
      this.setState({ zahntechnik: res.data });
    });

    cockpit.getSingleton('job').then(res => {
      this.setState({ job: res.data });
    });

    cockpit.getSingleton('anfahrt').then(res => {
      this.setState({ anfahrt: res.data });
    });

    cockpit.getSingleton('impressum').then(res => {
      this.setState({ impressum: res.data });
    });

    cockpit.getSingleton('datenschutz').then(res => {
      this.setState({ datenschutz: res.data });
    });

    cockpit.getCollection('dentists').then(res => {
      this.setState({ dentists: res.data });
    });

    cockpit.getCollection('team').then(res => {
      this.setState({ team: res.data });
    });

    cockpit.getCollection('leistungen').then(res => {
      this.setState({ leistungen: res.data });
    });
  }

  render() {
    let result = null;
    const { start, praxis, aerzte, dentists, team, leistungen, notdienst, zahntechnik, job, anfahrt, impressum, datenschutz } = this.state;

    if (start && praxis && aerzte && dentists && team && leistungen && notdienst && zahntechnik && job && anfahrt && impressum && datenschutz) {
      result = (
        <React.Fragment>
          <TransitionGroup>
            <CSSTransition
              key={ this.props.location.key }
              classNames="fade"
              timeout={{ enter: 300, exit: 300 }}
              mountOnEnter={ true }
              unmountOnExit={ true }
            >
              <Routes data={ this.state } />
            </CSSTransition>
          </TransitionGroup>
        </React.Fragment>
      );
    } else {
      result = null;
    }
    
    return (
      <Layout>
        <Header />
        <Main>
          { result }
        </Main>    
      </Layout>
    );
  }
}

export default withRouter(App);
