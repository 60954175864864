import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Helmet } from 'react-helmet';

// STYLES
const Container = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.layout.width.container};
  overflow: hidden;
  left: 0;
  right: 0;
  padding: 0;
  width: 100%;
  
  @media screen and (min-width: 960px) {
    position: absolute;
    padding: 0 50px;
  }
`;

// COMPONENT
const Page = props => (
  <React.Fragment>
    <Helmet><title>Gemeinschaftspraxis für Zahnheilkunde - Zahnärzte Neumann - München - { props.title }</title></Helmet>
    <Container>
      <Row center="sm">
        <Col xs={12}>{ props.children }</Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Page;