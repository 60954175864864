import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Start from './pages/Start';
import Praxis from './pages/Praxis';
import Aerzte from './pages/Aerzte';
import Team from './pages/Team';
import Leistungen from './pages/Leistungen';
import Service from './pages/Service';
import KontaktAnfahrt from './pages/KontaktAnfahrt';
import KontaktImpressum from './pages/KontaktImpressum';
import Datenschutz from './pages/Datenschutz';
import NotFound from './pages/NotFound';

const Routes = props => {
  const { data } = props;

  return (
    <Switch>
      <Route path="/" exact render={() => (
        <Redirect to="/start" />
      )} />

      <Route path="/start" render={props => (
        <Start {...props} data={ data.start } />
      )} />

      <Route path="/praxis" render={props => (
        <Praxis {...props} data={ data.praxis } />
      )} />
      
      <Route path="/aerzte/:slug?" render={props => (
        <Aerzte {...props} data={ data.aerzte } dentists={ data.dentists } />
      )} />

      {/* <Route path="/aerzte" render={props => (
        <Aerzte {...props} data={ data.aerzte } />
      )} /> */}

      <Route path="/team" render={props => (
        <Team {...props} data={ data.team } />
      )} />

      <Route path="/leistungen/:slug" render={props => (
        <Leistungen {...props} data={ data.leistungen } />
      )} />

      <Route path="/leistungen" render={props => (
        <Redirect to="/leistungen/untersuchung-und-diagnose" />
      )} />

      <Route path="/service/notdienst" render={props => (
        <Service {...props} data={ data.notdienst } />
      )} />

      <Route path="/service/zahntechnik" render={props => (
        <Service {...props} data={ data.zahntechnik } />
      )} />

      <Route path="/service/job-stellenangebot" render={props => (
        <Service {...props} data={ data.job } />
      )} />

      <Route path="/service" render={props => (
        <Redirect to="/service/notdienst" />
      )} />
      
      <Route path="/kontakt/anfahrt" render={props => (
        <KontaktAnfahrt {...props} data={ data.anfahrt } />
      )} />
      
      <Route path="/kontakt/impressum" render={props => (
        <KontaktImpressum {...props} data={ data.impressum } />
      )} />

      <Route path="/kontakt" render={props => (
        <Redirect to="/kontakt/anfahrt" />
      )} />

      <Route path="/datenschutz" render={props => (
        <Datenschutz {...props} data={ data.datenschutz } />
      )} />

      <Route path="*" exact={true} component={ NotFound } />
    </Switch>
  );
};

export default withRouter(Routes);