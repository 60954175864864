import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Page from '../components/Page';
import Member from '../components/Member';

// STYLES
const ScrollContainer = styled.div`
  @media screen and (min-width: 960px) {
    height: ${ props => props.theme.layout.scrollContainer.height };
    overflow: scroll;
  }
`;

// COMPONENT
const Team = props => {

  const { data } = props;
  console.log(data);
  return (
    <Page title="Team">
      <ScrollContainer>
        <Row>
          <Col xs={12} md={3}>
            <h1>Das Team</h1>
          </Col>
          <Col xs={12} md={9}>
            <Row>
              { data.entries.map(entry => (
                <Col xs={12} md={6}>
                  <Member name={ entry.name} image={ entry.image.path ? `${ process.env.REACT_APP_ROOT }/${ entry.image.path }` : null }>
                    <div dangerouslySetInnerHTML={{ __html: entry.text }}></div>
                  </Member>
                </Col>
              )) }
            </Row>
          </Col>
        </Row>
      </ScrollContainer>
    </Page>
  );
};

export default Team;