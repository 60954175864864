const Sitemap = [
  {
    label: 'Start',
    link: '/start',
    alias: 'start'
  },
  {
    label: 'Praxis',
    link: '/praxis',
    alias: 'praxis'
  },
  {
    label: 'Ärzte',
    link: '/aerzte',
    alias: 'aerzte'
  },
  {
    label: 'Team',
    link: '/team',
    alias: 'team'
  },
  {
    label: 'Leistungen',
    link: '/leistungen',
    alias: 'leistungen'
  },
  {
    label: 'Service',
    link: '/service',
    alias: 'service'
  },
  {
    label: 'Kontakt',
    link: '/kontakt',
    alias: 'kontakt'
  }
];

export default Sitemap;