import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// STYLES 
const FooterContainer = styled.footer`
  margin-bottom: 10px;
  border-top: 1px solid #d5d7d7;
  padding: 10px 0;
  text-align: right;
  font-size: ${ props => props.theme.font.size.small };
  bottom: 0;
  left: 50px;
  right: 50px;
  margin-top: 50px;
  
  @media screen and (min-width: 960px) {
    margin-top: 0;
    position: absolute;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${ props => props.theme.font.size.small };
  display: block;
  
  @media screen and (min-width: 960px) {
    display: inline;
  }
  
  &:hover {
    text-decoration: none;
  }
`;

const Seperator = styled.span`
  display: none; 

  @media screen and (min-width: 960px) {
    display: inline;
  }
`;

// COMPONENT
const Footer = props => {

  return (
    <FooterContainer>
      &copy; 2020 - Zahnärzte Neumann <Seperator>·</Seperator> <StyledLink to="/datenschutz">Datenschutzerklärung</StyledLink> <Seperator>·</Seperator> <StyledLink to="/kontakt/impressum">Impressum</StyledLink>
    </FooterContainer>
  );
};

export default Footer;