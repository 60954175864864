import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import Page from '../components/Page';
import LightBox from '../components/LightBox';

const Praxis = props => {

  const { data } = props;

  return (
    <Page title="Praxis">
      <Row>
        <Col xs={12} md={5}>
          <h1>{ data.headline }</h1>
          <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
        </Col>
        <Col xs={12} md={7}>
          <LightBox images={ data.gallery } />
        </Col>
      </Row>
    </Page>
  );
};

export default Praxis;