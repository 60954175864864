import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Theme from '../styles/Theme';
import GlobalStyles from '../styles/Global';
import Footer from './Footer';

// STYLES
const LayoutContainer = styled.div`
  max-width: ${props => props.theme.layout.width.container};
  margin: 0 auto;
  position: relative;
  width: 100%;
  clear: left;
  border: 0px solid;
  background: #FFF;
  box-shadow: 0px 0px 10px #CCC;
  padding: 0px 20px 10px 20px;
  
  @media screen and (min-width: 960px) {
    width: ${props => props.theme.layout.width.container};
    height: 600px;
    padding: 0px 50px 10px 50px;
  }
`;

// COMPONENT
const Layout = props => (
  <ThemeProvider theme={ Theme }>
    <GlobalStyles />
    <LayoutContainer>
      { props.children }
      <Footer />
    </LayoutContainer>
  </ThemeProvider>
);

export default Layout;