import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    font-size: 58%;

    @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
      font-size: 62.5%;
    }
  }

  body {
    font-family: ${props => props.theme.font.family.primary};
    font-size: ${props => props.theme.font.size.default};
    background-color: ${props => props.theme.color.surface.body};
    color: ${props => props.theme.color.text.body};
    line-height: 18px;
  }

  h1 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.h1};
    font-weight: normal;
    margin-top: 0;
    line-height: 1;
    margin-bottom: ${props => props.theme.layout.gap.h1};
  }

  h2 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.h2};
    font-weight: bold;
    margin-top: 0;
    margin-bottom: ${props => props.theme.layout.gap.h2};
  }

  h3 {
    font-size: ${props => props.theme.font.size.h3};
    font-weight: inherit;
    line-height: inherit;
    margin-top: 10px;
    margin-bottom: ${props => props.theme.layout.gap.h3};
  }

  p {
    margin-bottom: ${props => props.theme.layout.gap.default};
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }

  img {
    width: 100%;

    &.static {
      width: auto;
    }
  }

  /* PAGE TRANSITION */
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .fadeIn {
    animation-name: fadeIn;
    animation-duration: ${props => props.theme.fx.speed};
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }

  .container {
    margin: 0 auto;
    padding: 0 10px;
    max-width: ${props => props.theme.layout.width.container};
  }

  strong {
    font-weight: bold;
  }

  #root {
    @media screen and (min-width: 960px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
    }
  }

  .ril__inner {
    img {
      width: auto;
    }
  }
`;

export default GlobalStyles;