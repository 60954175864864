import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.gif'
import styled from 'styled-components';

// STYLES
const Image = styled.img`
  max-width: 220px;

  @media screen and (min-width: 500px) {
    max-width: none;
  }
`;

// COMPONENT
const Logo = props => (

  <Link to="/" {...props}>
    <Image src={ logo } alt="Gemeinschaftspraxis für Zahnheilkunde Neumann" />
  </Link>
);

export default Logo;