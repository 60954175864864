import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// STYLES
const StyledLink = styled(Link)`
  text-decoration: none;
  border-bottom: 1px solid ${ props => props.theme.color.text.body };

  &:hover {
    color: ${ props => props.theme.color.text.active };
    border-bottom: 1px solid ${ props => props.theme.color.text.active };
  }
`;

// COMPONENT
const IconLink = props => (
  <StyledLink to={ props.target }>» { props.label }</StyledLink>
);

export default IconLink;