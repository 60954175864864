import React from 'react';
import Page from '../components/Page';
import { Col, Row } from 'react-styled-flexboxgrid';
import IFrame from 'react-iframe';
import NavigationKontakt from '../components/NavigationKontakt';
import styled from 'styled-components';

// STYLES
const StyledIFrame = styled(IFrame)`
  border: none;
  width: 100%;
`;

// COMPONENT
const KontaktAnfahrt = props => {

  const { data } = props;

  return (
    <Page title="Anfahrt">
      <Row>
        <Col xs={12} md={3}>
          <h1>Kontakt</h1>
          <NavigationKontakt />
        </Col>
        <Col xs={12} md={9}>
          <Row>
            <Col xs={12} md={5}>
              <h2>{ data.headline }</h2>
              <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
            </Col>
            <Col xs={12} md={7}>
              <StyledIFrame 
                url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10654.656523157664!2d11.5028046!3d48.1167145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x89ea10da761b6d82!2sGemeinschaftspraxis%20f%C3%BCr%20Zahnheilkunde%20Neumann!5e0!3m2!1sde!2sde!4v1584539211669!5m2!1sde!2sde"
                width="540" height="380"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Page>
  );
};

export default KontaktAnfahrt;