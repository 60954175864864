import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { NavLink, Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Page from '../components/Page';

// STYLES
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;

  @media screen and (min-width: 960px) {
    margin-bottom: 0;
  }
`;

const NavItem = styled.li`
  margin-bottom: 10px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${ props => props.theme.color.text.active };
  }

  &.active {
    border-bottom: 1px solid ${ props => props.theme.color.text.body };
    color: ${ props => props.theme.color.text.active };
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${ props => props.theme.color.text.active };
    border-bottom: 1px solid ${ props => props.theme.color.text.active };
  }
`;

const StyledCol = styled(Col)`
  text-align: center;
  margin-top: 20px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  
  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
`;

const DetailImage = styled.img`
  width: 180px;
  height: auto;
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (min-width: 960px) {
    margin-bottom: 0;
  }
`;

const ScrollContainer = styled.div`
  @media screen and (min-width: 960px) {
    height: ${ props => props.theme.layout.scrollContainer.height };
    overflow: scroll;
  }
`;

// COMPONENT
const Aerzte = props => {
  
  const page = props.match.params.slug;
  const { data, dentists } = props;
  let content = null;

  console.log(dentists);

  if (page && dentists.entries.find(entry => entry.name_slug === page)) {
    const currentEntry = dentists.entries.find(entry => entry.name_slug === page);
    content = (
      <Details>
        <div>
          {currentEntry.image.path ? (
            <DetailImage src={ `${ process.env.REACT_APP_ROOT }/${ currentEntry.image.path }` } alt={ currentEntry.name } /> 
          ) : null}
        </div>
        <ScrollContainer>
          <h2>{ currentEntry.name_full }</h2>
          <small>Jahrgang { currentEntry.year_of_birth }</small>
          <div dangerouslySetInnerHTML={{ __html: currentEntry.text }}></div>
        </ScrollContainer>
      </Details>
    );
  } else {
    content = (
      <React.Fragment>
        <img src={ `${ process.env.REACT_APP_ROOT }/${ data.image.path }` } alt="" />
        <Row>
          <StyledCol xs={4}><StyledLink to="/aerzte/daniel-neumann">Daniel Neumann</StyledLink></StyledCol>
          <StyledCol xs={4}><StyledLink to="/aerzte/dr-karoline-neumann">Dr. Karoline Neumann</StyledLink></StyledCol>
          <StyledCol xs={4}><StyledLink to="/aerzte/dr-karl-neumann">Dr. Karl Neumann</StyledLink></StyledCol>
        </Row>
      </React.Fragment>
    );
  }

  return (
    <Page title="Praxis">
      <Row>
        <Col xs={12} md={3}>
          <h1>Die Ärzte</h1>
          <nav>
            <NavList>
              {dentists.entries.map(dentist => (
                <NavItem><StyledNavLink to={`/aerzte/${dentist.name_slug}`}>{dentist.name}</StyledNavLink></NavItem>
              ))}
              {/* <NavItem><StyledNavLink to="/aerzte/dr-karoline-neumann">Dr. Karoline Neumann</StyledNavLink></NavItem>
              <NavItem><StyledNavLink to="/aerzte/daniel-neumann">Daniel Neumann</StyledNavLink></NavItem>
              <NavItem><StyledNavLink to="/aerzte/dr-karl-neumann">Dr. Karl Neumann</StyledNavLink></NavItem>
              <NavItem><StyledNavLink to="/aerzte/anna-mierke">Anna Mierke</StyledNavLink></NavItem>
              <NavItem><StyledNavLink to="/aerzte/maria-becker">Maria Becker</StyledNavLink></NavItem> */}
            </NavList>
          </nav>
        </Col>
        <Col xs={12} md={9}>
          { content }
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Aerzte);