import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import AutoFitImage from 'react-image-autofit-frame';

// STYLES
const StyledCol = styled(Col)`
  margin-bottom: 20px;
`;

const ImageContainer = styled.div`

`;

const GridImage = styled.div`
  width: 100%;
  padding-bottom: 70%;
  background-position: center center;
  background-size: cover;
`;

const Container = styled.div`
  margin-top: 20px;
  
  @media screen and (min-width: 960px) {
    margin-top: 0;
  }
`;

// COMPONENT
class LightBox extends Component {
  
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
 
  render() {
    const { photoIndex, isOpen } = this.state;
 
    return (
      <Container>
        <div>
          <Row>
            { this.props.images.map((image, index) => (
              <StyledCol xs={6} sm={4} md={3} onClick={() => this.setState({ isOpen: true, photoIndex: index })}>
                <ImageContainer>
                  <GridImage style={{ backgroundImage: `url(${ process.env.REACT_APP_ROOT }/${ image.path })` }}></GridImage>
                </ImageContainer>
                {/* <AutoFitImage 
                  frameWidth="100%" 
                  frameHeight="75px" 
                  imgSrc={ `${ process.env.REACT_APP_ROOT }/${ image.path }` } 
                    
                /> */}
              </StyledCol>
            )) }
          </Row>
        </div>
 
        {isOpen && (
          <Lightbox
            mainSrc={ `${ process.env.REACT_APP_ROOT }/${ this.props.images[photoIndex].path }` }
            nextSrc={ `${ process.env.REACT_APP_ROOT }/${ this.props.images[(photoIndex + 1) % this.props.images.length].path }` }
            prevSrc={ `${ process.env.REACT_APP_ROOT }/${ this.props.images[(photoIndex + this.props.images.length - 1) % this.props.images.length].path }` }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.props.images.length - 1) % this.props.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.images.length,
              })
            }
          />
        )}
      </Container>
    );
  }
}

export default LightBox;