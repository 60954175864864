import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Page from '../components/Page';
import SliderShow from '../components/SlideShow';
import IconLink from '../components/IconLink';

// STYLES
const Times = styled.p`
  overflow: auto;
`;

const Time = styled.span`
  @media screen and (min-width: 960px) {
    display: block;
    float: right;
  }
`;

const Phone = styled.p`
  clear: both;

  @media screen and (min-width: 960px) {
    margin-bottom: 25px;
  }
`;

// COMPONENT
const Start = props => {

  const { data } = props;

  return (
    <Page title="Start">
      <SliderShow images={ data.slideshow } text={ data.headline } />
      <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
      <Row>
        <Col xs={12} md={4}>
          <p>Gemeinschaftspraxis für Zahnheilkunde Neumann</p>
          <p>Fürstenriederstr. 217<br/>81377</p>
          <IconLink target="/kontakt/anfahrt" label="Anfahrt" />
        </Col>
        <Col xs={12} md={4}>
          <Times>Sprechzeiten: Mo-Fr <Time>{ data.office_hours_early }</Time><Time>{ data.office_hours_late }</Time></Times>
          <Phone>Telefon: 089 741 400 70</Phone>
          <IconLink target="/service/notdienst" label="Notdienst" />
        </Col>
      </Row>
    </Page>
  );
};

export default Start;