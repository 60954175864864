import React from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';

// STYLES
const MemberContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const MemberImage = styled.img`
  width: 60px;
  max-width: 100%;
  margin-right: 15px;
  cursor: pointer;
  height: 90.22px;
`;

const MemberDescription = styled.div`
  p {
    margin: 0;
  }
`;

// COMPONENT
class Member extends React.Component {

  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { isOpen } = this.state;

    console.log(this.props);
    return (
      <MemberContainer onClick={ () => { this.setState({ isOpen: true, }) } }>
        { this.props.image ? <MemberImage src={ this.props.image } alt={ this.props.name } /> : null }
        <MemberDescription>
          <strong>{ this.props.name }</strong>
          { this.props.children }
        </MemberDescription>
  
        {isOpen && (
          <Lightbox
            mainSrc={ this.props.image }
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </MemberContainer>
    );
  }
}

export default Member;