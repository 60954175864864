import React from 'react';
import Page from '../components/Page';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import NavigationKontakt from '../components/NavigationKontakt';

// STYLES 
const ScrollContainer = styled.div`
  @media screen and (min-width: 960px) {
    height: ${ props => props.theme.layout.scrollContainer.height };
    overflow: scroll;
  }
`;

// COMPONENTS
const KontaktImpressum = props => {

  const { data } = props;

  return (
    <Page title="Impressum">
      <Row>
        <Col xs={12} md={3}>
          <h1>Kontakt</h1>
          <NavigationKontakt />
        </Col>
        <Col xs={12} md={9}>
          <ScrollContainer>
            <h2>{ data.headline }</h2>
            <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
          </ScrollContainer>
        </Col>
      </Row>
    </Page>
  );
};

export default KontaktImpressum;