import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

// STYLES
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    margin: 10px 0px 0px 20px;
  }
`;

const NavItem = styled.li`
  margin-bottom: 10px;

  li {
    margin-bottom: 5px;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${ props => props.theme.color.text.active };
  }

  &.active {
    border-bottom: 1px solid ${ props => props.theme.color.text.body };
    color: ${ props => props.theme.color.text.active };
  }
`;

// COMPONENT
const NavigationService = props => {
  
  return (
    <nav>
      <NavList>
        <NavItem><StyledNavLink to="notdienst">Notdienst</StyledNavLink></NavItem>
        <NavItem><StyledNavLink to="zahntechnik">Zahntechnik</StyledNavLink></NavItem>
        <NavItem><StyledNavLink to="job-stellenangebot">Job / Stellenangebot</StyledNavLink></NavItem>
      </NavList>
    </nav>
  );
};

export default withRouter(NavigationService);