import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import sitemap from '../Sitemap';
import closeIcon from '../assets/img/icon_close.svg';

// SYTLES
const Nav = styled.nav`
  position: fixed;
  background-color: #868688;
  top: 0;
  bottom: 0;
  left: 0;
  width: 90vw;
  max-width: 300px;
  z-index: 100;
  padding: 60px 20px; 
  font-size: 20px;
  transform: translateX(-100%);
  transition: all 0.2s ease-in-out;
  
  .mobileOpen & {
    transform: translateX(0);
  }

  @media screen and (min-width: 960px) {
    transform: none;
    font-size: 12px;
    padding: 0; 
    position: relative;
    background-color: transparent;
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  
  @media screen and (min-width: 960px) {
    display: flex;
  }
`;

const NavItem = styled.li`
  display: block;
  margin-right: 30px;
  margin-bottom: 10px;

  @media screen and (min-width: 960px) {
    margin-bottom: 0;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s;
  padding-bottom: 1px;
  border-bottom: 2px solid transparent;
  font-weight: bold;
  color: white;

  @media screen and (min-width: 960px) {
    color: #999;
  }

  &:hover {
    text-decoration: none;
    color: ${ props => props.theme.color.text.active };
  }

  &.active {
    text-decoration: inherit;
    border-bottom: 2px solid #ffde00;
    color: white;
    
    @media screen and (min-width: 960px) {
      color: ${ props => props.theme.color.text.active };
    }
  }
`;

const MenuClose = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: absolute; 
  right: 20px;
  top: 20px;
  cursor: pointer;

  img {
    width: 37px;
    height: 30px;
  }
`;

// COMPONENT
const Navigation = props => {

  return (
    <Nav {...props}>
      <MenuClose onClick={props.closeHandler}><img src={closeIcon} alt="" /></MenuClose>
      <NavList>
        { sitemap.map(page => (
          <NavItem key={ page.link } onClick={props.closeHandler}>
            <StyledNavLink
              to={ page.link }
              key={ page.alias }
            >

              { page.label }

            </StyledNavLink>
          </NavItem>
        )) }
      </NavList>
    </Nav>
  );
};

export default Navigation;