import React from 'react';
import styled from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';

// STYLES
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  margin-bottom: 10px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${ props => props.theme.color.text.active };
  }

  &.active {
    border-bottom: 1px solid ${ props => props.theme.color.text.body };
    color: ${ props => props.theme.color.text.active };
  }
`;

// COMPONENTS
const NavigationKontakt = props => {
  
  return (
    <nav>
      <NavList>
        <NavItem><StyledNavLink to="anfahrt">Anfahrt</StyledNavLink></NavItem>
        <NavItem><StyledNavLink to="impressum">Impressum</StyledNavLink></NavItem>
      </NavList>
    </nav>
  );
};

export default withRouter(NavigationKontakt);