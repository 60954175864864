import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import background from '../assets/img/start_img_gardient.jpg'

// STYLES
const SliderContainer = styled.div`
  width: 100%;
  background: url(${ background }) repeat-x;
  
  @media screen and (min-width: 960px) {
    height: 200px;
  }
`;

const Headline = styled.h1`
  color: white;
  font-size: 24px;
  line-height: 27px;
  font-weight: normal;
  padding-bottom: 20px;
  width: 300px;
  padding: 20px;
  margin: 0;
  
  @media screen and (min-width: 960px) {
    float: left;
  }
`;

const SliderWrapper = styled.div`
  @media screen and (min-width: 960px) {
    height: 200px;
    float: right;
    width: 555px;
  }
`;

const SliderImage = styled.img`
  max-width: 100%;
`;

// COMPONENT
const SlideShow = props => {

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
  };

  return (
    <SliderContainer>
      <Headline>{ props.text }</Headline>
      <SliderWrapper>
        <Slider { ... settings }>
          { props.images.map(image => (
            <SliderImage src={ `${ process.env.REACT_APP_ROOT }/${ image.path }` } alt="" key={ image.path } />
          )) }
        </Slider>
      </SliderWrapper>
      
    </SliderContainer>

  );
};

export default SlideShow;