import React from 'react';
import Page from '../components/Page';
import { Col, Row } from 'react-styled-flexboxgrid';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import NavigationLeistungen from '../components/NavigationLeistungen';

const Container = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: 960px) {
    margin-bottom: 0;
  }
`;

const Leistungen = props => {

  const { data } = props;
  const page = props.match.params.slug;
  let currentItem = data.entries.find(item => item.title_slug === page) || null;

  if (!currentItem) {
    return <Redirect to='/' />;
  }
  
  return (
    <Page title={ currentItem.title }>
      <Row>
        <Col xs={12} md={3}>
          <Container>
            <h1>Unsere Leistungen</h1>
            <NavigationLeistungen pages={ data.entries } activeCategory={ currentItem.category } />
          </Container>
        </Col>
        <Col xs={12} md={9}>
          <h2>{ currentItem.headline }</h2>
          <div dangerouslySetInnerHTML={{ __html: currentItem.text }}></div>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Leistungen);