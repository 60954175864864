import React from 'react';
import Page from '../components/Page';
import styled from 'styled-components';

// STYLES
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
`;

// COMPONENTS
const NotFound = props => {

  return (
    <Page title="404">
      <Container>Die Seite wurde nicht gefunden</Container>
    </Page>
  );
};

export default NotFound;