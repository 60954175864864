const Theme = {
  color: {
    primary: '#333333',
    secondary: '#FF0000',
    surface: {
      body: '#f1f1f1',
      light: '#FFFFFF',
      medium: '#A492A0',
      dark: '#F5F5F5'
    },
    text: {
      body: '#999',
      high: '#212121',
      medium: '#666666',
      disabled: '#9E9E9E',
      active: '#666'
    },
    button: {
      surface: '#C3CF3E',
      surfaceHover: '#DCE673',
      inline: '#A0A500',
      inlineHover: '#C0D212',
      inlineDark: '#4A2441',
      inlineDarkHover: '#AA3370'
    }
  },
  font: {
    family: {
      primary: '"Lucida Grande", Lucida, Verdana, Arial, sans-serif',
      secondary: '"Lucida Grande", Lucida, Verdana, Arial, sans-serif'
    },
    size: {
      default: '1.2rem',
      small: '1.0rem',
      h1: '2.4rem',
      h2: '1.8rem',
      h3: '1.4rem'
    }
  },
  layout: {
    width: {
      container: '960px',
      content: '850px'
    },
    padding: {
      tablet: '30px',
      phone: '15px'
    },
    gap: {
      default: '0.8rem',
      small: '1.5rem',
      medium: '3.8rem',
      large: '6.5rem',
      h1: '2.0rem',
      h2: '1.0rem',
      h3: '0.5rem'
    },
    scrollContainer: {
      height: '380px'
    }
  },
  fx: {
    speed: '0.3s'
  },
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 2,
    outerMargin: 0,
    mediaQuery: 'only screen',
    breakpoints: {
      xs: 0,
      sm: 40, 
      md: 59,
      lg: 75
    }
  },
  bp: {
    navigation: '780px'
  }
};

export default Theme;