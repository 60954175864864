import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

// STYlES
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    margin: 10px 0px 0px 20px;
  }
`;

const NavItem = styled.li`
  margin-bottom: 10px;

  li {
    margin-bottom: 5px;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${ props => props.theme.color.text.active };
  }

  &.active {
    border-bottom: 1px solid ${ props => props.theme.color.text.body };
    color: ${ props => props.theme.color.text.active };
  }
`;

// COMPONENT
class NavigationLeistungen extends React.Component {

  constructor(props) {
    super(props);
 
    this.state = {
      subnav: null
    };
  }

  componentDidMount() {
    const page = this.props.match.params.slug;
    let subnav = null;

    switch (page) {
      case 'untersuchung-und-diagnose':
      case 'b':
        subnav = 'untersuchung';
        break;
      case 'implantologie':
      case 'behandlung':
        subnav = 'behandlung';
        break;
      default: 
        break;
    }

    console.log(page);

    this.setState({ subnav: subnav })
  }

  render() {

    const { pages, activeCategory } = this.props;
    const navUntersuchung = pages.filter(item => item.category === 'Untersuchung' && item.title_slug !== 'untersuchung-und-diagnose');
    const navBehandlung = pages.filter(item => item.category === 'Behandlung');

    return (
      <nav>
        <NavList>
          <NavItem>
            <StyledNavLink to="/leistungen/untersuchung-und-diagnose">Untersuchung und Diagnose</StyledNavLink>
            { activeCategory === 'Untersuchung' && (
              <NavList>
                { navUntersuchung.map(item => (
                  <NavItem><StyledNavLink to={ `/leistungen/${item.title_slug}` }>{ item.title }</StyledNavLink></NavItem>
                )) }
              </NavList>
            )}
          </NavItem>
          <NavItem>
            <StyledNavLink to="/leistungen/implantologie">Behandlung</StyledNavLink>
            { activeCategory === 'Behandlung' && (
              <NavList>
                { navBehandlung.map(item => (
                  <NavItem><StyledNavLink to={ `/leistungen/${item.title_slug}` }>{ item.title }</StyledNavLink></NavItem>
                )) }
              </NavList>
            )}
          </NavItem>
          <NavItem><StyledNavLink to="/leistungen/nachsorge-und-prophylaxe">Nachsorge und Prophylaxe</StyledNavLink></NavItem>
        </NavList>
      </nav>
    );
  };
}

export default withRouter(NavigationLeistungen);